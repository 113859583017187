import { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 PRO React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgImg from "../../../MyAPP/img/cover.svg";

// Impoprt data of txt
import chooseusContent from "../../../MyAPP/Components/Files_json/lang_content/why_chooseus_contet_txt.json";

function Information() {
  const { lang } = useParams();

  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pb={6}
        >
          <MKTypography variant="h2" mb={1}>
            {lang === "en" ? "Why choose us" : "Pourquoi nous choisir"}
          </MKTypography>
          <MKTypography variant="body1" color="text">
            {lang === "en"
              ? "At Techmed, we offer consultations to all potential clients."
              : "Chez Techmed, nous offrons des consultations à tous les clients potentiels."}
          </MKTypography>
        </Grid>

        <Grid
          container
          item
          xs={11}
          spacing={3}
          alignItems="center"
          sx={{ mx: "auto" }}
        >
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgImg}
                icon="touch_app"
                title={
                  <>{lang === "en" ? "Get in Touch" : "Entrer en contact"}</>
                }
                // description={lang==="en" ? "description details" : "description details"}
              />
              <RotatingCardBack
                image={bgImg}
                title={
                  lang === "en"
                    ? "Discover Our Services Now"
                    : "Découvrez nos services Maintenant"
                }
                // description={lang==="en" ? "description details" : "description details"}
              />
            </RotatingCard>
          </Grid>

          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              {chooseusContent.map((chooseus) => (
                <Grid item xs={12} md={6}>
                  <DefaultInfoCard
                    icon={chooseus.icon}
                    title={lang === "en" ? chooseus.titleEN : chooseus.titleFR}
                    description={
                      lang === "en"
                        ? chooseus.descriptionEN
                        : chooseus.descriptionFR
                    }
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
