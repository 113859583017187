import { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// content txt
import serviceContent from "../Files_json/lang_content/services_content_txt.json";

// Material Kit 2 PRO React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Presentation page sections
import FaqCollapse from "../../../pages/Support/HelpCenter/components/FaqCollapse";

function BodyServices() {
  const [collapse, setCollapse] = useState(false);
  const { lang } = useParams();

  return (
    <>
      <MKBox component="section" py={12}>
        <Container>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={6} my={6}>
              <MKTypography
                variant="h2"
                align="center"
                fontWeight="bold"
                gutterBottom
              >
                {lang === "en"
                  ? "Discover Services Details"
                  : "Découvrir les détails des services"}
              </MKTypography>
              <MKBox mb={2}>
                <MKTypography variant="body2" align="center" color="text">
                  {lang === "en"
                    ? "We offer featured and recommended services to grow your business"
                    : "Nous offrons des services intéressés et recommandés pour développer votre entreprise"}
                </MKTypography>
              </MKBox>
            </Grid>
            <Grid item xs={12} md={10}>
              {serviceContent.map((service) => (
                <FaqCollapse
                  title={lang === "en" ? service.titleEN : service.titleFR}
                  open={collapse === service.id_service}
                  onClick={() =>
                    collapse === service.id_service
                      ? setCollapse(false)
                      : setCollapse(service.id_service)
                  }
                >
                  {lang === "en"
                    ? service.descriptionEN
                    : service.descriptionFR}
                </FaqCollapse>
              ))}
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default BodyServices;
