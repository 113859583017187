import { useEffect, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Mui icons
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Material Kit 2 PRO React components
import MKBox from "../../../components/MKBox";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";
// image
import backContact from "../../img/contact_back.jpg";

function BodyServices() {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Subject, setSubject] = useState("");
  const [Message, setMessage] = useState("");

  const [ErrorName, setErrorName] = useState("");
  const [ErrorEmail, setErrorEmail] = useState("");
  const [ErrorSubject, setErrorSubject] = useState("");
  const [ErrorMessage, setErrorMessage] = useState("");

  const [ColorResult, setColorResult] = useState("error");
  const [SubmitResult, setSubmitResult] = useState("");

  const { lang } = useParams();

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  function SubmitForm() {
    if (Name == "") {
      lang === "en"
        ? setErrorName("Name Required !")
        : setErrorName("Nom Obligatoire !");
    } else {
      setErrorName("");
    }
    if (Email == "") {
      lang === "en"
        ? setErrorEmail("Email Required !")
        : setErrorEmail("Email Obligatoire !");
    } else if (!isEmail(Email)) {
      lang === "en"
        ? setErrorEmail("Enter a valid email !")
        : setErrorEmail("Saisissez un e-mail valide !");
    } else {
      setErrorEmail("");
    }
    if (Subject == "") {
      lang === "en"
        ? setErrorSubject("Subject Required !")
        : setErrorSubject("Sujet Obligatoire !");
    } else {
      setErrorSubject("");
    }
    if (Message == "") {
      lang === "en"
        ? setErrorMessage("Message Required !")
        : setErrorMessage("Message Obligatoire !");
    } else {
      setErrorMessage("");
    }
    if (Name != "" && Subject != "" && Message != "" && ErrorEmail === "") {
      const FormContact = new FormData();
      FormContact.append("Name", Name);
      FormContact.append("Email", Email);
      FormContact.append("Subject", Subject);
      FormContact.append("Message", Message);
      axios.post("/PHP/contact.php", FormContact).then((response) => {
        if (response.data == 1) {
          setColorResult("success");
          lang === "en"
            ? setSubmitResult("Form sent Succesfully !")
            : setSubmitResult("Formulaire envoyé avec succès !");
          setTimeout(() => {
            setSubmitResult("");
          }, 1000);
        } else {
          setColorResult("error");
          lang === "en"
            ? setSubmitResult("Error..Try again !")
            : setSubmitResult("Erreur..Réessayez !");
          setTimeout(() => {
            setSubmitResult("");
          }, 1000);
        }
      });
    }
  }
  return (
    <>
      <MKBox component="section" py={{ xs: 0, lg: 6 }}>
        <Container>
          <Grid container item px={6}>
            <MKBox
              width="100%"
              bgColor="white"
              borderRadius="xl"
              shadow="xl"
              mb={6}
              sx={{ overflow: "hidden" }}
            >
              <Grid container spacing={3}>
                <Grid item xs={12} lg={7}>
                  <MKBox component="form" p={2} method="post">
                    <MKBox px={3} py={{ xs: 2, sm: 6 }}>
                      <MKTypography variant="h2" mb={1}>
                        {lang === "en" ? "" : ""}
                      </MKTypography>
                      <MKTypography variant="body1" color="text" mb={2}>
                        {lang === "en" ? "" : ""}
                      </MKTypography>
                    </MKBox>
                    <MKBox pt={0.5} pb={3} px={3}>
                      <Grid container>
                        <Grid item xs={12} pr={1} mb={3}>
                          <MKInput
                            variant="standard"
                            label={lang === "en" ? "My name is" : "Mon nom est"}
                            placeholder={
                              lang === "en" ? "Full Name" : "Nom et prénom"
                            }
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={(e) => setName(e.target.value)}
                          />
                          {ErrorName != "" ? (
                            <div className="error_contact">
                              <InfoIcon color="error" />
                              <MKTypography color="error" variant="span" ml={1}>
                                {ErrorName}
                              </MKTypography>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={12} pr={1} mb={3}>
                          <MKInput
                            variant="standard"
                            label={
                              lang === "en" ? "My Email is" : "Mon Email est"
                            }
                            placeholder={
                              lang === "en"
                                ? "ex : email@gmail.com"
                                : "ex : email@gmail.com"
                            }
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {ErrorEmail != "" ? (
                            <div className="error_contact">
                              <InfoIcon color="error" />
                              <MKTypography color="error" variant="span" ml={1}>
                                {ErrorEmail}
                              </MKTypography>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={12} pr={1} mb={3}>
                          <MKInput
                            variant="standard"
                            label={
                              lang === "en" ? "I'm looking for" : "Je recherche"
                            }
                            placeholder={
                              lang === "en"
                                ? "What you love"
                                : "Ce que vous aimez"
                            }
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            onChange={(e) => setSubject(e.target.value)}
                          />
                          {ErrorSubject != "" ? (
                            <div className="error_contact">
                              <InfoIcon color="error" />
                              <MKTypography color="error" variant="span" ml={1}>
                                {ErrorSubject}
                              </MKTypography>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                        <Grid item xs={12} pr={1} mb={3}>
                          <MKInput
                            variant="standard"
                            label={
                              lang === "en"
                                ? "Your message Here..."
                                : "Votre message Ici..."
                            }
                            placeholder={
                              lang === "en"
                                ? "I want to say that..."
                                : "Je veux dire que..."
                            }
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                            multiline
                            rows={6}
                            onChange={(e) => setMessage(e.target.value)}
                          />
                          {ErrorMessage != "" ? (
                            <div className="error_contact">
                              <InfoIcon color="error" />
                              <MKTypography color="error" variant="span" ml={1}>
                                {ErrorMessage}
                              </MKTypography>
                            </div>
                          ) : (
                            <></>
                          )}
                          {SubmitResult != "" ? (
                            <div className="error_contact">
                              {ColorResult == "error" ? (
                                <InfoIcon color="error" />
                              ) : (
                                <CheckCircleIcon color="success" />
                              )}
                              <MKTypography
                                color={
                                  ColorResult == "error" ? "error" : "success"
                                }
                                variant="span"
                                ml={1}
                              >
                                {SubmitResult}
                              </MKTypography>
                            </div>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        xs={12}
                        md={6}
                        justifyContent="flex-end"
                        textAlign="right"
                        ml="auto"
                      >
                        <MKButton
                          onClick={() => SubmitForm()}
                          variant="gradient"
                          color="info"
                        >
                          {lang === "en" ? "Send Message" : "Envoyer  message"}
                        </MKButton>
                      </Grid>
                    </MKBox>
                  </MKBox>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  position="relative"
                  px={0}
                  sx={{
                    backgroundImage: ({
                      palette: { gradients },
                      functions: { rgba, linearGradient },
                    }) =>
                      `${linearGradient(
                        rgba(gradients.info.main, 0.8),
                        rgba(gradients.info.state, 0.8)
                      )}, url(${backContact})`,
                    backgroundSize: "cover",
                  }}
                >
                  <MKBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    height="100%"
                  >
                    <MKBox py={6} pl={6} pr={{ xs: 6, sm: 12 }} my="auto">
                      <MKTypography variant="h3" color="white" mb={1}>
                        Contact Information
                      </MKTypography>
                      <MKTypography
                        variant="body2"
                        color="white"
                        opacity={0.8}
                        mb={3}
                      >
                        {lang === "en"
                          ? "Fill up the form and our Team will get back to you within 24 hours."
                          : "Remplissez le formulaire et notre équipe vous répondra dans les 24 heures."}
                      </MKTypography>
                      <MKBox display="flex" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-phone" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          00 33 64 31 40 222
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-envelope" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          contact@techmed-consulting.net
                        </MKTypography>
                      </MKBox>
                      <MKBox display="flex" color="white" p={1}>
                        <MKTypography variant="button" color="white">
                          <i className="fas fa-map-marker-alt" />
                        </MKTypography>
                        <MKTypography
                          component="span"
                          variant="button"
                          color="white"
                          opacity={0.8}
                          ml={2}
                          fontWeight="regular"
                        >
                          2 B STREET PIERRE DE RONSARD, 78200 MANTES-LA-JOLIE,
                          France
                        </MKTypography>
                      </MKBox>
                      <MKBox mt={3}>
                        <MKButton
                          variant="text"
                          color="white"
                          size="large"
                          target="_blank"
                          href="https://www.linkedin.com/company/techmedconsulting/"
                          iconOnly
                        >
                          <i
                            className="fab fa-linkedin"
                            style={{ fontSize: "1.25rem" }}
                          />
                        </MKButton>
                      </MKBox>
                    </MKBox>
                  </MKBox>
                </Grid>
              </Grid>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default BodyServices;
