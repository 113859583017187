/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Cookies from "js-cookie";

// react-router components
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useParams,
} from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";

// @mui material components
import Icon from "@mui/material/Icon";

// MyPages
import Header from "MyAPP/Components/Header/Header";
import Body from "MyAPP/Components/Body/Body";
import Footer from "MyAPP/Components/Footer/Footer";
import BodyServices from "MyAPP/Components/BodyServices/BodyServices";
import BodyReferences from "MyAPP/Components/BodyReferences/BodyReferences";
import BodyContact from "MyAPP/Components/BodyContact/BodyContact";
import BodyJobs from "MyAPP/Components/BodyJobs/BodyJobs";
import DetailsProjects from "MyAPP/Components/DetailsProjects/DetailsProjects";

// Mui Icons Import
import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import ElectricalServicesOutlinedIcon from "@mui/icons-material/ElectricalServicesOutlined";
import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";

export default function App() {
  const { lang } = useParams();

  const routes = [
    {
      icon: <Icon>home</Icon>,
      name: "Home",
      route: "/:lang",
      component: (
        <>
          <Header />
          <Body />
          <Footer />
        </>
      ),
    },
    {
      icon: <ElectricalServicesOutlinedIcon />,
      name: "Services",
      route: "/:lang/services",
      component: (
        <>
          <Header />
          <BodyServices />
          <Footer />
        </>
      ),
    },
    {
      icon: <BugReportRoundedIcon />,
      name: "Reference",
      route: "/:lang/references",
      component: (
        <>
          <Header />
          <BodyReferences />
          <Footer />
        </>
      ),
    },
    {
      icon: <Icon>work</Icon>,
      name: "Jobs",
      route: "/:lang/jobs",
      component: (
        <>
          <Header />
          <BodyJobs />
          <Footer />
        </>
      ),
    },
    {
      icon: <Icon>work</Icon>,
      name: "Jobs",
      route: "/:lang/Emplois",
      component: (
        <>
          <Header />
          <BodyJobs />
          <Footer />
        </>
      ),
    },
    {
      icon: <ConnectWithoutContactOutlinedIcon />,
      name: "Contacts",
      route: ":lang/Contact",
      component: (
        <>
          <Header />
          <BodyContact />
          <Footer />
        </>
      ),
    },
    {
      icon: <ConnectWithoutContactOutlinedIcon />,
      name: "detailsProjects",
      route: "/:lang/detailsProjects/:idProject",
      component: (
        <>
          <Header />
          <DetailsProjects />
          <Footer />
        </>
      ),
    },
  ];

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            path={route.route}
            element={route.component}
            key={route.key}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to={lang ? "/en" : "/fr"} />} />
      </Routes>
    </ThemeProvider>
  );
}
