// react-router-dom components
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Mui Icons
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

// Images
import logoCT from "../../img/logo-ct-dark.png";
import logo from "../../img/logo.png";

// Material Kit 2 PRO React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

function Footer() {
  const navigate = useNavigate();
  const { lang } = useParams();

  const date = new Date().getFullYear();

  const brand = { name: "Material Kit 2 PRO", image: logoCT, route: "/" };
  const socials = [
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/creativetim",
    },
    {
      icon: <GitHubIcon />,
      link: "https://github.com/creativetimofficial",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ];
  const menus = [
    {
      name: lang === "en" ? "Home" : "Acceuil",
      items: [
        {
          name: lang === "en" ? "Home" : "Acceuil",
          href: lang === "en" ? "/en" : "/fr",
        },
      ],
    },
    {
      name: lang === "en" ? "About Us" : "À propos",
      items: [
        {
          name: "Services",
          href: lang === "en" ? "/en/services" : "/fr/services",
        },
        {
          name: lang === "en" ? "References" : "Références",
          href: lang === "en" ? "/en/references" : "/fr/references",
        },
      ],
    },
    {
      name: lang === "en" ? "Help & Support" : "Support d'aide",
      items: [
        {
          name: "Contact",
          href: lang === "en" ? "/en/Contact" : "/fr/Contact",
        },
      ],
    },
    {
      name: lang === "en" ? "Recruitment" : "Recrutement",
      items: [
        {
          name: lang === "en" ? "Jobs" : "Emplois",
          href: lang === "en" ? "/en/Jobs" : "/fr/Emplois",
        },
      ],
    },
  ];
  const copyright = (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} Material Kit by{" "}
      <MKTypography
        component="a"
        href="/"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        TechMed
      </MKTypography>
      .
    </MKTypography>
  );

  return (
    <MKBox component="footer">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MKBox>
              <Link to={lang === "en" ? "/en" : "/fr"}>
                <MKBox
                  component="img"
                  src={logo}
                  alt="logo"
                  maxWidth="2rem"
                  mb={2}
                />
              </Link>
              <MKTypography variant="h6">TechMed Consulting</MKTypography>
            </MKBox>
            <MKBox display="flex" alignItems="center" mt={3}>
              <MKTypography
                key="/git"
                component="a"
                href="https://www.linkedin.com/company/techmedconsulting/"
                target="_blank"
                rel="noreferrer"
                variant="h5"
                color="dark"
                opacity={0.8}
                ml={1}
              >
                <LinkedInIcon />
              </MKTypography>
            </MKBox>
          </Grid>
          {menus.map(({ name: title, items }) => (
            <Grid key={title} item xs={6} md={2} sx={{ mb: 3 }}>
              <MKTypography
                display="block"
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"
                mb={1}
              >
                {title}
              </MKTypography>
              <MKBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                {items.map(({ name, route, href }) => (
                  <MKBox
                    key={name}
                    component="li"
                    p={0}
                    m={0}
                    lineHeight={1.25}
                  >
                    {href ? (
                      <MKTypography
                        component="a"
                        onClick={(e) => [e.preventDefault(), navigate(name)]}
                        href="#"
                        rel="noreferrer"
                        variant="button"
                        fontWeight="regular"
                        textTransform="capitalize"
                      >
                        {name}
                      </MKTypography>
                    ) : (
                      <></>
                    )}
                  </MKBox>
                ))}
              </MKBox>
            </Grid>
          ))}
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {lang === "en"
              ? `All rights reserved. Copyright © 2019-${date} TechMed-Consulting`
              : `Tous les droits sont réservés © 2019-${date} TechMed-Consulting`}
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Footer;
