import "./DetailsReferences.css";

import { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import MKButton from "../../../components/MKButton";
import MKAlert from "../../../components/MKAlert";

import RaisedBlogCard from "../../../examples/Cards/BlogCards/RaisedBlogCard";

// json Data
import ReferenceData from "../Files_json/references_data.json";

function DetailsReferences() {
  // valeu acceted : web, app, uxui, digitalmarketing, graphdesign, seo
  const [Targeted, setTargeted] = useState("all");
  const { lang } = useParams();

  return (
    <>
      <Container>
        <div className="refereces_titles">
          <MKButton
            onClick={() => setTargeted("all")}
            sx={{ ml: 1, mt: 1 }}
            variant={Targeted === "all" ? "contained" : "outlined"}
            color={Targeted === "all" ? "primary" : "info"}
          >
            {lang === "en" ? "All" : "Tout"}
          </MKButton>
          <MKButton
            onClick={() => setTargeted("web")}
            sx={{ ml: 1, mt: 1 }}
            variant={Targeted === "web" ? "contained" : "outlined"}
            color={Targeted === "web" ? "primary" : "info"}
          >
            web App
          </MKButton>
          <MKButton
            onClick={() => setTargeted("app")}
            sx={{ ml: 1, mt: 1 }}
            variant={Targeted === "app" ? "contained" : "outlined"}
            color={Targeted === "app" ? "primary" : "info"}
          >
            {lang === "en" ? "Mobile Apps" : "Application mobile"}
          </MKButton>
          <MKButton
            onClick={() => setTargeted("uxui")}
            sx={{ ml: 1, mt: 1 }}
            variant={Targeted === "uxui" ? "contained" : "outlined"}
            color={Targeted === "uxui" ? "primary" : "info"}
          >
            UX/UI
          </MKButton>
          <MKButton
            onClick={() => setTargeted("graphdesign")}
            sx={{ ml: 1, mt: 1 }}
            variant={Targeted === "graphdesign" ? "contained" : "outlined"}
            color={Targeted === "graphdesign" ? "primary" : "info"}
          >
            Graphique Design
          </MKButton>
          <MKButton
            onClick={() => setTargeted("digitalmarketing")}
            sx={{ ml: 1, mt: 1 }}
            variant={Targeted === "digitalmarketing" ? "contained" : "outlined"}
            color={Targeted === "digitalmarketing" ? "primary" : "info"}
          >
            {lang === "en" ? "Digital Marketing" : "Marketing Digital"}
          </MKButton>
          <MKButton
            onClick={() => setTargeted("seo")}
            sx={{ ml: 1, mt: 1 }}
            variant={Targeted === "seo" ? "contained" : "outlined"}
            color={Targeted === "seo" ? "primary" : "info"}
          >
            {lang === "en" ? "SEO optimization" : "Optimisation SEO"}
          </MKButton>
        </div>
        <Grid container justifyContent="center">
          {ReferenceData.map((ref) =>
            Targeted === "all" ? (
              <Grid
                key={ref.id_prj}
                container
                justifyContent="center"
                xs={12}
                sm={12}
                md={6}
                lg={4}
                item
              >
                <RaisedBlogCard
                  image={ref.img_url}
                  title={
                    lang === "en"
                      ? ref.projectNameEN.slice(
                          0,
                          ref.projectNameEN.length > 210
                            ? 210
                            : ref.projectNameEN.length
                        )
                      : ref.projectNameFR.slice(
                          0,
                          ref.projectNameFR.length > 210
                            ? 210
                            : ref.projectNameFR.length
                        )
                  }
                  description={
                    lang === "en"
                      ? ref.description_prjEN.slice(
                          0,
                          ref.description_prjEN.length > 210
                            ? 210
                            : ref.description_prjEN.length
                        )
                      : ref.description_prjFR.slice(
                          0,
                          ref.description_prjFR.length > 210
                            ? 210
                            : ref.description_prjFR.length
                        )
                  }
                  action={{
                    type: "internal",
                    route:
                      lang === "en"
                        ? `/en/detailsProjects/${ref.projectNameEN}`
                        : `/fr/detailsProjects/${ref.projectNameEN}`,
                    color: "info",
                    label: lang === "en" ? "More Details" : "Plus de détails",
                  }}
                />
              </Grid>
            ) : (
              <></>
            )
          )}
        </Grid>
        <Grid container justifyContent="center">
          {ReferenceData.map((ref) =>
            Targeted === ref.prj_type ? (
              <Grid
                key={ref.id_prj}
                container
                justifyContent="center"
                xs={12}
                sm={12}
                md={6}
                lg={4}
                item
              >
                <RaisedBlogCard
                  image={ref.img_url}
                  title={
                    lang === "en"
                      ? ref.projectNameEN.slice(
                          0,
                          ref.projectNameEN.length > 210
                            ? 210
                            : ref.projectNameEN.length
                        )
                      : ref.projectNameFR.slice(
                          0,
                          ref.projectNameFR.length > 210
                            ? 210
                            : ref.projectNameFR.length
                        )
                  }
                  description={
                    lang === "en"
                      ? ref.description_prjEN.slice(
                          0,
                          ref.description_prjEN.length > 210
                            ? 210
                            : ref.description_prjEN.length
                        )
                      : ref.description_prjFR.slice(
                          0,
                          ref.description_prjFR.length > 210
                            ? 210
                            : ref.description_prjFR.length
                        )
                  }
                  action={{
                    type: "internal",
                    route:
                      lang === "en"
                        ? `/en/detailsProjects/${ref.projectNameEN}`
                        : `/fr/detailsProjects/${ref.projectNameEN}`,
                    color: "info",
                    label: lang === "en" ? "More Details" : "Plus de détails",
                  }}
                />
              </Grid>
            ) : (
              <></>
            )
          )}
        </Grid>

        <Grid container justifyContent="center">
          {Targeted != "app" && Targeted != "web" && Targeted != "all" ? (
            <Grid item xs={10} mt={5}>
              <MKAlert color="info">
                {lang === "en"
                  ? "No Projects Found"
                  : "Aucun Projects Trouvé !"}
              </MKAlert>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Container>
    </>
  );
}

export default DetailsReferences;
