import { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// content txt
import serviceContent from "../Files_json/lang_content/services_content_txt.json";

// Material Kit 2 PRO React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 PRO React examples
import SimpleInfoCard from "../../../examples/Cards/InfoCards/SimpleInfoCard";

function Services() {
  const { lang } = useParams();

  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          mx="auto"
          textAlign="center"
          pb={6}
        >
          <MKTypography variant="h2" mb={1}>
            {lang === "en" ? "Who what we offer" : "Qui ce que nous offrons"}
          </MKTypography>
          <MKTypography variant="body1" color="text">
            {lang === "en"
              ? "We offer interested and recommended services to grow your business"
              : "Nous offrons des services intéressés et recommandés pour développer votre entreprise"}
          </MKTypography>
        </Grid>
        <Grid container spacing={3}>
          {serviceContent.map((service) => (
            <Grid item xs={12} md={6} lg={4}>
              <SimpleInfoCard
                color={service.color}
                icon={service.icon}
                title={lang === "en" ? service.titleEN : service.titleFR}
                description={
                  lang === "en" ? service.descriptionEN : service.descriptionFR
                }
                direction="center"
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Services;
