import "./Reviews.css";

import { useEffect, useState } from "react";
import Cookies from "js-cookie";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 PRO React examples
import DefaultReviewCard from "../../../examples/Cards/ReviewCards/DefaultReviewCard";

// Content For Reviews
import reviews from "../Files_json/lang_content/reviews_content_txt.json";
import clientLogo from "../Files_json/lang_content/client_logo.json";

function Reviews() {
  const [Lang, setLang] = useState(Cookies.get("lang"));
  useEffect(() => {
    if (Lang != Cookies.get("lang")) {
      setLang(Cookies.get("lang"));
    }
  });

  return (
    <MKBox component="section" py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          mx="auto"
          textAlign="center"
        >
          <MKTypography variant="h2" mb={2}>
            {Lang === "eng"
              ? "What our customers said"
              : "Ce que nos clients ont dit"}
          </MKTypography>
          <MKTypography variant="body2" color="text" mb={2}>
            {Lang === "eng"
              ? "This is what satisfied customers say about our quality of services during the development process of their projects."
              : "C'est que des clients satisfaits disent de notre qualité de service lors du processus de développement de leurs projets."}
          </MKTypography>
        </Grid>
        <Grid container spacing={3} mt={8}>
          {reviews.map((review) => (
            <Grid
              className="reviews_grid"
              item
              xs={12}
              md={8}
              lg={4}
              mb={{ xs: 3, lg: 0 }}
            >
              <DefaultReviewCard
                image={review.img_person_url}
                name={review.Name_commented}
                date={review.date_commented}
                review={Lang === "eng" ? review.CommentEN : review.CommentFR}
                rating={review.rating}
              />
            </Grid>
          ))}{" "}
        </Grid>
        <Divider sx={{ my: 6 }} />
        <Grid container spacing={3} justifyContent="center">
          {clientLogo.map((logo) => (
            <Grid item xs={6} lg={2}>
              <MKBox
                component="img"
                src={logo.url_img_logo}
                alt="facebook"
                width="100%"
                opacity={0.6}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Reviews;
