import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Cookies from "js-cookie";
import { Link, useNavigate, useParams } from "react-router-dom";

import "./DetailsJobs.css";

// Mui icons
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloudDownloadRoundedIcon from "@mui/icons-material/CloudDownloadRounded";
import DownloadDoneRoundedIcon from "@mui/icons-material/DownloadDoneRounded";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

// @mui material <components></components>
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import NativeSelect from "@mui/material/NativeSelect";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import TextareaAutosize from "@mui/material/TextareaAutosize";

// Import data of Jobs
import jobsData from "../Files_json/jobs_data.json";
import jobsContent from "../Files_json/lang_content/jobs_content_txt.json";

// Material Kit 2 PRO React components
import MKBox from "../../../components/MKBox";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";
import MKAlert from "../../../components/MKAlert";

// Presentation page sections
import FaqCollapse from "../../../pages/Support/HelpCenter/components/FaqCollapse";

function DetailsJobs() {
  const [collapse, setCollapse] = useState(false);

  const { lang } = useParams();

  const [JobTargeted, setJobTargeted] = useState(false);
  const currentDate = new Date();
  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  // Var For Appling
  const [Name, setName] = useState("");
  const [ErrorName, setErrorName] = useState("");
  const [PreName, setPreName] = useState("");
  const [ErrorPreName, setErrorPreName] = useState("");
  const [Address, setAddress] = useState("");
  const [ErrorAddress, setErrorAddress] = useState("");
  const [Email, setEmail] = useState("");
  const [ErrorEmail, setErrorEmail] = useState("");
  const [Phone, setPhone] = useState("");
  const [ErrorPhone, setErrorPhone] = useState("");
  const [City, setCity] = useState("");
  const [ErrorCity, setErrorCity] = useState("");
  const [CodePostal, setCodePostal] = useState("");
  const [ErrorCodePostal, setErrorCodePostal] = useState("");
  const [Civility, setCivility] = useState("Male");
  const [ErrorCivility, setErrorCivility] = useState("");
  const [School, setSchool] = useState("");
  const [ErrorSchool, setErrorSchool] = useState("");
  const [Diploma, setDiploma] = useState("");
  const [ErrorDiploma, setErrorDiploma] = useState("");
  const [Linkedin, setLinkedin] = useState("");
  const [ErrorLinkedin, setErrorLinkedin] = useState("");
  const [Disponibility, setDisponibility] = useState("12-12-1212");
  const [ErrorDisponibility, setErrorDisponibility] = useState("");
  const [Contract, setContract] = useState("Traineeship");
  const [ErrorContract, setErrorContract] = useState("");
  const [StudyLevel, setStudyLevel] = useState("bac+1");
  const [ErrorStudyLevel, setErrorStudyLevel] = useState("");
  const [Commentaire, setCommentaire] = useState("");
  const [ErrorCommentaire, setErrorCommentaire] = useState("");
  const [CV, setCV] = useState("");
  const [ErrorCV, setErrorCV] = useState("");
  const [SubscribeToNews, setSubscribeToNews] = useState("No");
  const [ErrorSubscribeToNews, setErrorSubscribeToNews] = useState("");
  const [HowKnowUs, setHowKnowUs] = useState("A natural Google result");
  const [ErrorHowKnowUs, setErrorHowKnowUs] = useState("");

  const [SubmitResult, setSubmitResult] = useState("");
  const [SubmitColor, setSubmitColor] = useState("");

  // Spam
  const [NumberValidate1, setNumberValidate1] = useState(
    Math.floor(Math.random() * 10)
  );
  const [NumberValidate2, setNumberValidate2] = useState(
    Math.floor(Math.random() * 10)
  );
  const [Result, setResult] = useState(NumberValidate1 + NumberValidate2);
  const [SpamNumber, setSpamNumber] = useState("");
  const [ErrorSpamNumber, setErrorSpamNumber] = useState("");

  function ApplyForJobs() {
    if (Email == "") {
      {
        lang === "en"
          ? setErrorEmail("Email Required !")
          : setErrorEmail("E-mail Obligatoire !");
      }
    } else if (!isEmail(Email)) {
      {
        lang === "en"
          ? setErrorEmail("Enter validate Email !")
          : setErrorEmail("Saisissez e-mail de valider !");
      }
    } else {
      setErrorEmail("");
    }
    if (Email == "") {
      {
        lang === "en"
          ? setErrorPhone("Phone Required !")
          : setErrorPhone("Téléphone Obligatoire !");
      }
    } else if (Phone.length < 10) {
      {
        lang === "en"
          ? setErrorPhone("At Least 10 Numbers !")
          : setErrorPhone("Au moins 10 numéros !");
      }
    } else {
      setErrorPhone("");
    }
    if (Name == "") {
      {
        lang === "en"
          ? setErrorName("First Name Required !")
          : setErrorName("Nom Obligatoire !");
      }
    } else {
      setErrorName("");
    }
    if (PreName == "") {
      {
        lang === "en"
          ? setErrorPreName("Last Name Required !")
          : setErrorPreName("Prénom Obligatoire !");
      }
    } else {
      setErrorPreName("");
    }
    if (Address == "") {
      {
        lang === "en"
          ? setErrorAddress("Address Required !")
          : setErrorAddress("Address Obligatoire !");
      }
    } else {
      setErrorAddress("");
    }
    if (City == "") {
      {
        lang === "en"
          ? setErrorCity("City Required !")
          : setErrorCity("Ville Obligatoire !");
      }
    } else {
      setErrorCity("");
    }
    if (CodePostal == "") {
      {
        lang === "en"
          ? setErrorCodePostal("CodePostal Required !")
          : setErrorCodePostal("CodePostal Obligatoire !");
      }
    } else {
      setErrorCodePostal("");
    }
    if (Civility == "") {
      {
        lang === "en"
          ? setErrorCivility("Civility Required !")
          : setErrorCivility("Civilité Obligatoire !");
      }
    } else {
      setErrorCivility("");
    }
    if (School == "") {
      {
        lang === "en"
          ? setErrorSchool("School Required !")
          : setErrorSchool("Établissement Obligatoire !");
      }
    } else {
      setErrorSchool("");
    }
    if (Diploma == "") {
      {
        lang === "en"
          ? setErrorDiploma("Diploma Required !")
          : setErrorDiploma(" Obligatoire !");
      }
    } else {
      setErrorDiploma("");
    }
    if (Linkedin == "") {
      {
        lang === "en"
          ? setErrorLinkedin("Linkedin Required !")
          : setErrorLinkedin("Linkedin Obligatoire !");
      }
    } else {
      setErrorLinkedin("");
    }
    if (Disponibility == "") {
      {
        lang === "en"
          ? setErrorDisponibility("Disponibility Required !")
          : setErrorDisponibility("Disponibilité Obligatoire !");
      }
    } else {
      setErrorDisponibility("");
    }
    if (Contract == "") {
      {
        lang === "en"
          ? setErrorContract("Contract Required !")
          : setErrorContract("Contrat Obligatoire !");
      }
    } else {
      setErrorContract("");
    }
    if (StudyLevel == "") {
      {
        lang === "en"
          ? setErrorStudyLevel("StudyLevel Required !")
          : setErrorStudyLevel("Niveau d'étude Obligatoire !");
      }
    } else {
      setErrorStudyLevel("");
    }
    if (Commentaire == "") {
      {
        lang === "en"
          ? setErrorCommentaire("Commentaire Required !")
          : setErrorCommentaire("Commentaire Obligatoire !");
      }
    } else {
      setErrorCommentaire("");
    }
    if (SubscribeToNews == "") {
      {
        lang === "en"
          ? setErrorSubscribeToNews("Subscribe Required !")
          : setErrorSubscribeToNews("S'abonner Obligatoire !");
      }
    } else {
      setErrorSubscribeToNews("");
    }
    if (HowKnowUs == "") {
      {
        lang === "en"
          ? setErrorHowKnowUs("HowKnowUs Required !")
          : setErrorHowKnowUs("Comment nous connaître Obligatoire !");
      }
    } else {
      setErrorHowKnowUs("");
    }
    if (SpamNumber == "") {
      {
        lang === "en"
          ? setErrorSpamNumber("Spam Result Required !")
          : setErrorSpamNumber("Résultat de Spam Obligatoire !");
      }
    } else if (SpamNumber != Result) {
      {
        lang === "en"
          ? setErrorSpamNumber("Incorrect spam result !")
          : setErrorSpamNumber("Spam résultat incorrect !");
      }
    } else {
      setErrorSpamNumber("");
    }
    if (CV == "") {
      {
        lang === "en"
          ? setErrorCV("CV Required !")
          : setErrorCV("CV Obligatoire !");
      }
    } else if (CV != "") {
      if (!CV.name.toLowerCase().includes(".pdf")) {
        {
          lang === "en"
            ? setErrorCV("Format Accepted is .PDF !")
            : setErrorCV("Format Accepté est .PDF !");
        }
      } else {
        setErrorCV("");
      }
    }

    if (
      ErrorName != "" &&
      ErrorPreName != "" &&
      ErrorAddress != "" &&
      ErrorEmail != "" &&
      ErrorPhone != "" &&
      ErrorCity != "" &&
      ErrorCodePostal != "" &&
      ErrorCivility != "" &&
      ErrorSchool != "" &&
      ErrorDiploma != "" &&
      ErrorLinkedin != "" &&
      ErrorDisponibility != "" &&
      ErrorContract != "" &&
      ErrorStudyLevel != "" &&
      ErrorCommentaire != "" &&
      ErrorCV != "" &&
      ErrorSubscribeToNews != "" &&
      ErrorHowKnowUs != ""
    ) {
      const formJob = new FormData();
      formJob.append("Name", Name);
      formJob.append("PreName", PreName);
      formJob.append("Address", Address);
      formJob.append("Email", Email);
      formJob.append("Phone", Phone);
      formJob.append("City", City);
      formJob.append("CodePostal", CodePostal);
      formJob.append("Civility", Civility);
      formJob.append("School", School);
      formJob.append("Diploma", Diploma);
      formJob.append("Linkedin", Linkedin);
      formJob.append("Disponibility", Disponibility);
      formJob.append("Contract", Contract);
      formJob.append("StudyLevel", StudyLevel);
      formJob.append("Commentaire", Commentaire);
      formJob.append("CV", CV);
      formJob.append("SubscribeToNews", SubscribeToNews);
      formJob.append("HowKnowUs", HowKnowUs);
      formJob.append("JobTargeted", JobTargeted);

      axios.post("/PHP/jobs.php", formJob).then((response) => {
        console.log("test");
        if (response.data == 1) {
          setSubmitColor("success");
          setName("");
          setPreName("");
          setAddress("");
          setEmail("");
          setPhone("");
          setCity("");
          setCodePostal("");
          setCivility("");
          setSchool("");
          setDiploma("");
          setLinkedin("");
          setDisponibility("");
          setContract("");
          setStudyLevel("");
          setCommentaire("");
          setCV("");
          setSubscribeToNews("");
          setHowKnowUs("");
          lang === "en"
            ? setSubmitResult(
                "You're Appling successfully, We will contact you as soon possible !"
              )
            : setSubmitResult(
                "vous postulez avec succès, nous vous contacterons dès que possible !"
              );
          setTimeout(() => {
            setSubmitColor("");
          }, 1000);
        } else {
          setSubmitColor("error");
          lang === "en"
            ? setSubmitResult("Ops !, error..Try again.")
            : setSubmitResult("Ops !, erreur..Réessayez.");
          setTimeout(() => {
            setSubmitColor("");
          }, 1000);
        }
      });
    }
  }

  return (
    <>
      <MKBox className="body_jobs" component="section" py={12}>
        <Container>
          {jobsData.length != 0 ? (
            <Grid container justifyContent="center">
              <Grid item xs={12} md={6} my={6}>
                <MKTypography
                  variant="h2"
                  align="center"
                  fontWeight="bold"
                  gutterBottom
                >
                  {lang === "en" ? jobsContent.titleEN : jobsContent.titleFR}
                </MKTypography>
                <MKBox mb={2}>
                  <MKTypography variant="body2" align="center" color="text">
                    {lang === "en"
                      ? jobsContent.descriptionEN
                      : jobsContent.descriptionFR}
                  </MKTypography>
                </MKBox>
              </Grid>
              {collapse == 99 ? (
                <Grid item xs={12} md={10}>
                  <FaqCollapse
                    title={
                      lang === "en"
                        ? `Appling For ${JobTargeted}`
                        : `Postuler Pour ${JobTargeted}`
                    }
                    open={collapse === 99}
                    onClick={() =>
                      collapse === 99 ? setCollapse(false) : setCollapse(99)
                    }
                  >
                    <p className="jobs_details_title">
                      {lang === "en"
                        ? "--Personal details"
                        : "--Détails personnels"}
                    </p>
                    <div className="jobs_applly">
                      <div className="jobs_applly_col">
                        <MKInput
                          sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                          onChange={(e) => setName(e.target.value)}
                          label={lang === "en" ? "First Name" : "Nom"}
                          fullWidth
                          info
                        />
                        {ErrorName != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorName}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="jobs_applly_col">
                        <MKInput
                          sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                          onChange={(e) => setPreName(e.target.value)}
                          label={lang === "en" ? "Last Name" : "Prénom"}
                          fullWidth
                          info
                        />
                        {ErrorPreName != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorPreName}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="jobs_applly_col">
                        <MKInput
                          sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                          onChange={(e) => setAddress(e.target.value)}
                          label={lang === "en" ? "Address" : "Address"}
                          fullWidth
                          info
                        />
                        {ErrorAddress != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorAddress}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="jobs_applly_col">
                        <MKInput
                          sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                          onChange={(e) => setEmail(e.target.value)}
                          label={lang === "en" ? "Email" : "E-mail"}
                          fullWidth
                          info
                        />
                        {ErrorEmail != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorEmail}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="jobs_applly_col">
                        <MKInput
                          sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                          type="number"
                          onChange={(e) => setPhone(e.target.value)}
                          label={lang === "en" ? "Phone" : "Téléphone"}
                          fullWidth
                          info
                        />
                        {ErrorPhone != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorPhone}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="jobs_applly_col">
                        <MKInput
                          sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                          onChange={(e) => setCity(e.target.value)}
                          label={lang === "en" ? "City" : "Ville"}
                          fullWidth
                          info
                        />
                        {ErrorCity != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorCity}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="jobs_applly_col">
                        <MKInput
                          sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                          type="number"
                          onChange={(e) => setCodePostal(e.target.value)}
                          label={lang === "en" ? "CodePostal" : "CodePostal"}
                          fullWidth
                          info
                        />
                        {ErrorCodePostal != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorCodePostal}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="jobs_applly_col">
                        <FormControl fullWidth style={{ background: "#fff" }}>
                          <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                          >
                            {lang === "en" ? "Civility" : "Civilité"}
                          </InputLabel>
                          <NativeSelect
                            onChange={(e) => setCivility(e.target.value)}
                            defaultValue="Male"
                            inputProps={{
                              name: "age",
                              id: "uncontrolled-native",
                            }}
                          >
                            <option value="Male">
                              {lang === "en" ? "Male" : "M"}
                            </option>
                            <option value="Female">
                              {lang === "en" ? "Female" : "Mme"}
                            </option>
                          </NativeSelect>
                        </FormControl>

                        {ErrorCivility != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorCivility}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <br />
                    <br />
                    <p className="jobs_details_title">
                      {lang === "en"
                        ? "--Education details"
                        : "--Détails sur l'éducation"}
                    </p>
                    <div className="jobs_applly">
                      <div className="jobs_applly_col">
                        <MKInput
                          sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                          onChange={(e) => setSchool(e.target.value)}
                          label={lang === "en" ? "School" : "Etablissement"}
                          fullWidth
                          info
                        />
                        {ErrorSchool != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorSchool}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="jobs_applly_col">
                        <MKInput
                          sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                          onChange={(e) => setDiploma(e.target.value)}
                          label={lang === "en" ? "Diploma" : "Diplôme"}
                          fullWidth
                          info
                        />
                        {ErrorDiploma != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorDiploma}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="jobs_applly_col">
                        <MKInput
                          sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                          onChange={(e) => setLinkedin(e.target.value)}
                          label={
                            lang === "en" ? "Link Linkedin" : "Lien Linkedin"
                          }
                          fullWidth
                          info
                        />
                        {ErrorLinkedin != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorLinkedin}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="jobs_applly_col">
                        <MKInput
                          sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                          type="date"
                          defaultValue={`${currentDate.getFullYear()}-${
                            currentDate.getMonth() + 1
                          }-${currentDate.getDate()}`}
                          onChange={(e) => setDisponibility(e.target.value)}
                          label={
                            lang === "en" ? "Disponibility" : "Disponibilité"
                          }
                          fullWidth
                          info
                        />
                        {ErrorDisponibility != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorDisponibility}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="jobs_applly_col">
                        <FormControl className="width_form_bac">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            {lang === "en" ? "Contract" : "Contrat"}
                          </FormLabel>
                          <RadioGroup
                            onChange={(e) => setContract(e.target.value)}
                            defaultValue="Traineeship"
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="Traineeship"
                              control={<Radio />}
                              label={lang === "en" ? "Traineeship" : "Stage"}
                            />
                            <FormControlLabel
                              value="Alternation"
                              control={<Radio />}
                              label={
                                lang === "en" ? "Alternation" : "Alternance"
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                        {ErrorContract != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorContract}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="jobs_applly">
                      <div className="jobs_applly_col">
                        <FormControl className="width_form_bac">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            {lang === "en" ? "Study Level" : "Niveau d'étude"}
                          </FormLabel>
                          <RadioGroup
                            onChange={(e) => setStudyLevel(e.target.value)}
                            defaultValue="bac+1"
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value="bac+1"
                              control={<Radio />}
                              label="bac+1"
                            />
                            <FormControlLabel
                              value="bac+2"
                              control={<Radio />}
                              label="bac+2"
                            />
                            <FormControlLabel
                              value="bac+3"
                              control={<Radio />}
                              label="bac+3"
                            />
                            <FormControlLabel
                              value="bac+4"
                              control={<Radio />}
                              label="bac+4"
                            />
                            <FormControlLabel
                              value="bac+5"
                              control={<Radio />}
                              label="bac+5"
                            />
                          </RadioGroup>
                        </FormControl>
                        {ErrorStudyLevel != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorStudyLevel}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="jobs_applly">
                      <div className="jobs_applly_col">
                        <TextareaAutosize
                          sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                          className="txtarea_"
                          aria-label="minimum height"
                          minRows={5}
                          placeholder={
                            lang === "en"
                              ? "Describe what you think you'll bring to TechMed, tell us about an experience or tell us a developer joke."
                              : "Décrivez ce que vous pensez apporter chez TechMed, faites-nous part d'une expérience ou racontez-nous une blague de développeur."
                          }
                          onChange={(e) => setCommentaire(e.target.value)}
                          style={{ width: 200 }}
                        />
                        {ErrorCommentaire != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorCommentaire}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="add_education_inputs">
                      <label htmlFor="formId">
                        <input
                          onChange={(e) => setCV(e.target.files[0])}
                          accept="pdf"
                          name="CV"
                          type="file"
                          id="formId"
                          hidden
                        />
                        <a className="btn_dowlaod dowload_png">
                          {CV == "" ? (
                            <>
                              <span className="dowload_png_txt">
                                {lang === "en"
                                  ? "Select File"
                                  : "Choisir le Fichier"}
                              </span>
                              <CloudDownloadRoundedIcon />
                            </>
                          ) : (
                            <>
                              <span className="dowload_png_txt">
                                {lang === "en"
                                  ? "File Selected"
                                  : "Fichier sélectionné"}
                              </span>
                              <DownloadDoneRoundedIcon />
                            </>
                          )}
                        </a>
                      </label>
                      {ErrorCV != "" ? (
                        <div className="error_contact">
                          <InfoIcon color="error" />
                          <MKTypography color="error" variant="span" ml={1}>
                            {ErrorCV}
                          </MKTypography>
                        </div>
                      ) : (
                        <></>
                      )}
                      {ErrorCV == "" ? (
                        <div className="error_contact">
                          <MKTypography color="text" variant="span" ml={1}>
                            {lang === "en"
                              ? "Your CV.Format Accepte .PDF"
                              : "Votre CV.Format Accepté .PDF"}
                          </MKTypography>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <br />
                    <br />
                    <p className="jobs_details_title">
                      {lang === "en"
                        ? "--Help Us to Improve"
                        : "--Aidez-nous à nous améliorer"}
                    </p>
                    <div className="jobs_applly">
                      <div className="jobs_applly_col">
                        <FormControl className="width_form_bac">
                          <FormLabel id="demo-row-radio-buttons-group-label">
                            {lang === "en"
                              ? "Subscribe to Newsletter"
                              : "S'abonner à la Newsletter"}
                          </FormLabel>
                          <RadioGroup
                            onChange={(e) => setSubscribeToNews(e.target.value)}
                            defaultValue={lang === "en" ? "No" : "Non"}
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                          >
                            <FormControlLabel
                              value={lang === "en" ? "No" : "Non"}
                              control={<Radio />}
                              label={lang === "en" ? "No" : "Non"}
                            />
                            <FormControlLabel
                              value={lang === "en" ? "Yes" : "Oui"}
                              control={<Radio />}
                              label={lang === "en" ? "Yes" : "Oui"}
                            />
                          </RadioGroup>
                        </FormControl>
                        {ErrorSubscribeToNews != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorSubscribeToNews}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="jobs_applly">
                      <div className="jobs_applly_col">
                        <FormControl fullWidth style={{ background: "#fff" }}>
                          <InputLabel
                            variant="standard"
                            htmlFor="uncontrolled-native"
                          >
                            {lang === "en"
                              ? "You know TechMed by"
                              : "Vous connaissez TechMed par"}
                          </InputLabel>
                          <NativeSelect
                            onChange={(e) => setHowKnowUs(e.target.value)}
                            defaultValue="A natural Google result"
                            inputProps={{
                              name: "How Know Us",
                              id: "uncontrolled-native",
                            }}
                          >
                            <option value="A natural Google result">
                              {lang === "en"
                                ? "A natural Google result"
                                : "Un résultat naturel Google"}
                            </option>
                            <option value="Advertising">
                              {lang === "en" ? "Advertising" : "Une publicité"}
                            </option>
                            <option value="A social network">
                              {lang === "en"
                                ? "A social network"
                                : "Un réseau social"}
                            </option>
                            <option value="A job website">
                              {lang === "en"
                                ? "A job website"
                                : "Un site d'offre d'emploi"}
                            </option>
                            <option value="A partner website">
                              {lang === "en"
                                ? "A partner website"
                                : "Un site partenaire"}
                            </option>
                            <option value="The TechMed Newsletter">
                              {lang === "en"
                                ? "The TechMed Newsletter"
                                : "La newsletter TechMed"}
                            </option>
                          </NativeSelect>
                        </FormControl>

                        {ErrorHowKnowUs != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorHowKnowUs}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <div className="jobs_applly">
                      <div className="jobs_applly_col">
                        <MKInput
                          sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                          type="number"
                          onChange={(e) => setSpamNumber(e.target.value)}
                          label={`${NumberValidate1} + ${NumberValidate2} ? `}
                          fullWidth
                          info
                        />
                        {ErrorSpamNumber != "" ? (
                          <div className="error_contact">
                            <InfoIcon color="error" />
                            <MKTypography color="error" variant="span" ml={1}>
                              {ErrorSpamNumber}
                            </MKTypography>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                    <br />
                    <br />
                    <MKButton
                      onClick={() => ApplyForJobs()}
                      className="btn_apply_jobs"
                      variant="gradient"
                      color="primary"
                      w={100}
                    >
                      {lang === "en" ? "Apply Now" : "postuler Maintenant"}
                    </MKButton>
                    {SubmitColor == "error" ? (
                      <div className="error_contact" style={{ marginTop: 30 }}>
                        <InfoIcon
                          color="error"
                          style={{ height: 30, width: 30 }}
                        />
                        <MKTypography
                          color="error"
                          style={{ fontSize: "large" }}
                          variant="span"
                          ml={1}
                        >
                          {SubmitResult}
                        </MKTypography>
                      </div>
                    ) : (
                      <></>
                    )}
                    {SubmitColor == "success" ? (
                      <div className="error_contact" style={{ marginTop: 30 }}>
                        <CheckCircleIcon
                          color="success"
                          style={{ height: 30, width: 30 }}
                        />
                        <MKTypography
                          color="success"
                          variant="span"
                          style={{ fontSize: "large" }}
                          ml={1}
                        >
                          {SubmitResult}
                        </MKTypography>
                      </div>
                    ) : (
                      <></>
                    )}
                  </FaqCollapse>
                </Grid>
              ) : (
                <Grid item xs={12} md={10}>
                  {jobsData.map((job) => (
                    <FaqCollapse
                      title={lang === "en" ? job.JobsTitleEN : job.JobsTitleFR}
                      open={collapse === job.Id_jos}
                      onClick={() =>
                        collapse === job.Id_jos
                          ? setCollapse(false)
                          : setCollapse(job.Id_jos)
                      }
                    >
                      <div className="jobs_details">
                        <p className="jobs_details_title">
                          {lang === "en" ? "Tasck : " : "Tâche : "}
                        </p>
                        <p>{lang === "en" ? job.TascksEN : job.TascksFR}</p>
                        <br />
                        <br />
                        <br />
                        <p className="jobs_details_title">
                          {lang === "en"
                            ? "Candidate profile : "
                            : "Profil du candidat : "}
                        </p>
                        <p>{lang === "en" ? job.profileEN : job.profileFR}</p>
                        <br />
                        <br />
                        <br />
                        <p className="jobs_details_title">
                          {lang === "en" ? "Salary  : " : "le salaire : "}
                        </p>
                        <p>{lang === "en" ? job.SalaryEN : job.SalaryFR}</p>
                        <br />
                        <br />
                        <br />
                        <p className="jobs_details_title">
                          {lang === "en" ? "Benefits : " : "Avantages : "}
                        </p>
                        <p>{lang === "en" ? job.BenefitsEN : job.BenefitsFR}</p>
                        <br />
                        <br />
                        <MKButton
                          onClick={() => [
                            setCollapse(99),
                            setJobTargeted(
                              lang === "en" ? job.JobsTitleEN : job.JobsTitleFR
                            ),
                          ]}
                          className="btn_apply_jobs"
                          variant="gradient"
                          color="primary"
                          w={100}
                        >
                          {lang === "en" ? "Apply Now" : "postuler Maintenant"}
                        </MKButton>
                      </div>
                    </FaqCollapse>
                  ))}
                </Grid>
              )}{" "}
            </Grid>
          ) : (
            <Grid container justifyContent="center">
              <MKAlert color="info">
                {lang === "en"
                  ? "No job offers published !"
                  : "Aucune offre d'emploi publiée !"}
              </MKAlert>
            </Grid>
          )}
        </Container>
      </MKBox>
    </>
  );
}

export default DetailsJobs;
