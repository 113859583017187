import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { useParams } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Presentation page sections
import Counters from "../../../pages/Presentation/sections/Counters";
import Information from "../../../pages/Presentation/sections/Information";

import Services from "../Services/Services";
import Teams from "../Teams/Teams";
import Reviews from "../Reviews/Reviews";
import NewsLetters from "../NewsLetters/NewsLetters";

function Body() {
  const { lang } = useParams();

  return (
    <>
      <Helmet>
        <title>{lang === "en" ? "Home" : "Acceuil"} | TechMedConsulting</title>
      </Helmet>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <Information />
        <Services />
        <Teams />
        <Reviews />
        <NewsLetters />
      </Card>
    </>
  );
}

export default Body;
