import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";

// Material Kit 2 PRO React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// Import data of Counter
import CounterContent from "../../../MyAPP/Components/Files_json/lang_content/counter_content_txt.json.json";

function Counters() {
  const { lang } = useParams();

  return (
    <MKBox component="section" py={3}>
      <Container>
        <Grid container item xs={12} lg={9} sx={{ mx: "auto" }}>
          {CounterContent.map((counter) => (
            <Grid item xs={12} md={4}>
              <DefaultCounterCard
                count={counter.number_counter}
                suffix={counter.widthSufix === "yes" ? "+" : ""}
                title={lang === "en" ? counter.titleEN : counter.titleFR}
                description={
                  lang === "en" ? counter.descriptionEN : counter.descriptionFR
                }
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Counters;
