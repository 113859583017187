import "./DetailsProjects.css";

import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import Helmet from "react-helmet";

import { useParams } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Divider from "@mui/material/Divider";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "../../../components/MKButton";
import MKAlert from "../../../components/MKAlert";

import RaisedBlogCard from "../../../examples/Cards/BlogCards/RaisedBlogCard";
import DefaultBackgroundCard from "../../../examples/Cards/BackgroundCards/DefaultBackgroundCard";

// HelpCenter page components
import ListItem from "../../../pages/Support/HelpCenter/components/ListItem";

// json Data
import ReferenceData from "../Files_json/references_data.json";

// Images
import bgImage1 from "../../img/city.jpg";

function DetailsProjects() {
  const { idProject } = useParams();
  const { lang } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <>
      <Container>
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
              rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          {ReferenceData.map((prj) =>
            prj.projectNameEN.toLowerCase() == idProject.toLowerCase() ? (
              <>
                <Helmet>
                  <title>
                    {lang === "en" ? prj.projectNameEN : prj.projectNameFR} |
                    {lang === "en" ? " Details Projects" : " Détails Projets"}
                  </title>
                  <link rel="icon" href={prj.img_url} />
                </Helmet>
                <MKBox component="section" py={12} key={prj.id_prj}>
                  <Grid
                    container
                    item
                    xs={10}
                    lg={5}
                    sx={{
                      mx: "auto",
                      textAlign: "center",
                      flexDirection: "column",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <MKBox
                      width="3rem"
                      height="3rem"
                      borderRadius="lg"
                      shadow="md"
                      variant="gradient"
                      bgColor="info"
                      color="white"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <Icon fontSize="small" sx={{ opacity: 0.8 }}>
                        view_in_ar_rounded
                      </Icon>
                    </MKBox>
                    <MKTypography variant="h3" mt={3}>
                      {lang === "en" ? prj.projectNameEN : prj.projectNameFR}
                    </MKTypography>
                  </Grid>
                  <Grid
                    container
                    spacing={3}
                    alignItems="center"
                    sx={{ mt: 6 }}
                  >
                    <Grid item xs={12} md={4} sx={{ ml: "auto" }}>
                      <DefaultBackgroundCard
                        image={prj.img_url_desc1}
                        label="   "
                        title={
                          lang === "en" ? prj.projectNameEN : prj.projectNameFR
                        }
                        description="   "
                        action={{
                          type: "external",
                          route: prj.prj_url,
                          label: lang === "en" ? "visit" : "visiter",
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={5}
                      sx={{ mr: "auto", ml: { xs: 0, md: 6 } }}
                    >
                      <ListItem
                        title={lang === "en" ? "THE SOLUTION" : "LA SOLUTION"}
                      >
                        {" "}
                      </ListItem>
                      {lang === "en"
                        ? prj.img1_descr_txtEN
                        : prj.img1_descr_txtFR}
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: { xs: 2, sm: 8 }, mx: 12 }} />
                  <Grid container spacing={3} alignItems="center">
                    <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
                      <ListItem
                        title={lang === "en" ? "THE CHALLENGE" : "LE DÉFI"}
                      >
                        {" "}
                      </ListItem>
                      {lang === "en"
                        ? prj.img2_descr_txtEN
                        : prj.img2_descr_txtFR}
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={4}
                      sx={{
                        mr: "auto",
                        ml: { xs: 0, md: 6 },
                      }}
                    >
                      <DefaultBackgroundCard
                        image={prj.img_url_desc2}
                        label="   "
                        title={
                          lang === "en" ? prj.projectNameEN : prj.projectNameFR
                        }
                        description=""
                        action={{
                          type: "external",
                          route: prj.prj_url,
                          label: lang === "en" ? "visit" : "visiter",
                        }}
                      />
                    </Grid>
                  </Grid>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      // alignItems: "center",
                      marginTop: 50,
                      flexDirection: "column",
                    }}
                  >
                    <ListItem
                      title={lang === "en" ? "THE RESULTS" : "LES RÉSULTATS"}
                    >
                      {" "}
                    </ListItem>
                    <p style={{ textAlign: "left" }}>
                      {lang === "en" ? prj.result_txtEN : prj.result_txtFR}
                    </p>
                  </div>
                </MKBox>
              </>
            ) : (
              <></>
            )
          )}
        </Card>
      </Container>
    </>
  );
}

export default DetailsProjects;
