/* eslint prefer-arrow-callback: [ "error", { "allowUnboundThis": false } ] */
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Mui Icons
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

// Material Kit 2 PRO React components
import MKBox from "../../../components/MKBox";
import MKInput from "../../../components/MKInput";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";

function NewsLetters() {
  const [emailNewsLetter, setemailNewsLetter] = useState("");
  const [Palette, setPalette] = useState("error");
  const [AlertContent, setAlertContent] = useState("");

  const { lang } = useParams();

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
  // News Letter
  function NewsLetter() {
    if (emailNewsLetter == "") {
      lang === "en"
        ? setAlertContent("Hello, Email is Required !")
        : setAlertContent("Bonjour, l'e-mail est obligatoire !");
      setPalette("error");
    } else if (!isEmail(emailNewsLetter)) {
      lang === "en"
        ? setAlertContent("Hello, Enter a validate Email !")
        : setAlertContent("Bonjour, Saisissez un e-mail valide !");
      setPalette("error");
    } else {
      const NewsForm = new FormData();
      NewsForm.append("email_news", emailNewsLetter);
      axios.post("/PHP/newsletter.php", NewsForm).then((response) => {
        if (response.data == 1) {
          setPalette("success");
          lang === "en"
            ? setAlertContent("Hello, Email Registered successfully !")
            : setAlertContent("Bonjour, E-mail Enregistré avec succès !");
        } else {
          setPalette("error");
          lang === "en"
            ? setAlertContent("Hello, error..Try again!")
            : setAlertContent("Bonjour, erreur..Réessayez !");
        }
      });
    }
  }

  return (
    <>
      <MKBox component="section" py={12}>
        <Container className="back_img_news">
          <Grid container spacing={3}>
            {AlertContent == "" ? (
              <Grid item xs={12} lg={5} mr="auto">
                <MKTypography variant="h4" mb={1} sx={{ color: "#fff" }}>
                  {lang === "en"
                    ? "Take advantage of our best news"
                    : "Profitez de nos meilleurs nouveautés"}
                </MKTypography>
                <MKTypography
                  variant="body2"
                  color="text"
                  sx={{ color: "#fff" }}
                >
                  {lang === "en"
                    ? "Join our newsletter and get news in your inbox every week!"
                    : "Inscrivez-vous à notre newsletter et recevez des nouvelles dans votre boîte de réception chaque semaine!"}
                </MKTypography>
              </Grid>
            ) : (
              <div className="error_newletter">
                {Palette === "error" ? (
                  <InfoIcon color="error" />
                ) : (
                  <CheckCircleIcon color="success" />
                )}
                <MKTypography
                  color={Palette === "error" ? "error" : "success"}
                  variant="span"
                  ml={1}
                >
                  {AlertContent}
                </MKTypography>
              </div>
            )}

            <Grid
              item
              xs={12}
              lg={6}
              flexDirection="column"
              justifyContent="center"
              ml="auto"
            >
              <MKBox component="form" method="" action="">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={8}>
                    <MKInput
                      sx={{ backgroundColor: "#fff", borderRadius: 12 }}
                      onChange={(e) => setemailNewsLetter(e.target.value)}
                      label={
                        lang === "en" ? "Your Email..." : "Votre E-mail..."
                      }
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <MKButton
                      onClick={() => NewsLetter()}
                      variant="gradient"
                      color="info"
                      fullWidth
                      sx={{ height: "100%" }}
                    >
                      {lang === "en" ? "Subscribe" : "S'abonner"}
                    </MKButton>
                  </Grid>
                </Grid>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default NewsLetters;
