import { useEffect, useRef, useState } from "react";
import Cookies from "js-cookie";
import $ from "jquery";
import { useNavigate, useParams } from "react-router-dom";

// typed-js
import * as Typed from "typed.js";

import Icon from "@mui/material/Icon";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import ConnectWithoutContactOutlinedIcon from "@mui/icons-material/ConnectWithoutContactOutlined";
import ElectricalServicesOutlinedIcon from "@mui/icons-material/ElectricalServicesOutlined";
import BugReportRoundedIcon from "@mui/icons-material/BugReportRounded";
import TranslateOutlinedIcon from "@mui/icons-material/TranslateOutlined";

// MyPages
import MyHeader from "MyAPP/Components/Header/Header";
import Body from "MyAPP/Components/Body/Body";
import Footer from "MyAPP/Components/Footer/Footer";
import BodyServices from "MyAPP/Components/BodyServices/BodyServices";
import BodyReferences from "MyAPP/Components/BodyReferences/BodyReferences";
import BodyContact from "MyAPP/Components/BodyContact/BodyContact";
import BodyJobs from "MyAPP/Components/BodyJobs/BodyJobs";

// Material Kit 2 PRO React components
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";
import DefaultNavbar from "../../../examples/Navbars/DefaultNavbar";

// Images
import bgImage from "../../img/back.svg";
import cover from "../../img/cover.svg";

function Header() {
  const navigate = useNavigate();
  const { lang } = useParams();

  const typedJSRef = useRef(null);

  // Setting up typedJS
  useEffect(() => {
    const typedJS = new Typed(typedJSRef.current, {
      strings:
        lang === "en"
          ? ["web design", "web Apps", "mobile App"]
          : ["web design", "applications Web", "Mobile Application"],
      typeSpeed: 70,
      backSpeed: 70,
      backDelay: 200,
      startDelay: 500,
      loop: true,
    });

    return () => typedJS.destroy();
  });

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function RefreshPage() {
    window.location.reload();
  }

  // Drop Dow
  const [dropdown, setDropdown] = useState(null);

  const openDropdown = ({ currentTarget }) => setDropdown(currentTarget);
  const closeDropdown = () => setDropdown(null);

  // Styles Drop Down
  const iconStyles = {
    ml: 1,
    fontWeight: "bold",
    transition: "transform 200ms ease-in-out",
  };

  const dropdownIconStyles = {
    transform: dropdown ? "rotate(180deg)" : "rotate(0)",
    ...iconStyles,
  };

  return (
    <MKBox
      paddingTop={window.location.href.length > 34 ? 20 : 0}
      component="header"
      position="relative"
      opacity={1}
      sx={{
        backgroundColor: "#fff",
      }}
    >
      <MKBox
        sx={{
          backgroundColor: "#ffffffcc",
          zIndex: 999999,
          backdropFilter: "saturate(200%) blur(30px)",
          paddingBottom: 2,
          paddingTop: 2,
        }}
        component="nav"
        position="fixed"
        top="0.5rem"
        width="100%"
        opacity={1}
      >
        <Container>
          <Grid container flexDirection="row" alignItems="center">
            <MKTypography
              component={Link}
              onClick={(e) => [
                e.preventDefault(),
                navigate(lang === "en" ? "/en" : "/fr"),
              ]}
              href="#"
              variant="button"
              fontWeight="regular"
              py={0.8125}
              mr={2}
              sx={{ fontWeight: 600 }}
              style={{ color: "#7C819C" }}
            >
              TechMed
            </MKTypography>
            <MKButton
              onClick={openDropdown}
              variant="outlined"
              color="text"
              sx={{ display: { xs: "block", lg: "none" }, ml: "auto" }}
            >
              <MKBox component="i" color="text" className="fas fa-bars" />
            </MKButton>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  onClick={(e) => [
                    e.preventDefault(),
                    navigate(lang === "en" ? "/en" : "/fr"),
                  ]}
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  p={1}
                >
                  {lang === "en" ? "Home" : "Acceuil"}
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  onClick={(e) => [
                    e.preventDefault(),
                    navigate(lang === "en" ? "/en/services" : "/fr/services"),
                  ]}
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  p={1}
                >
                  {lang === "en" ? "Services" : "Services"}
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  onClick={(e) => [
                    e.preventDefault(),
                    navigate(
                      lang === "en" ? "/en/references" : "/fr/references"
                    ),
                  ]}
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  p={1}
                >
                  {lang === "en" ? "References" : "Références"}
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  onClick={(e) => [
                    e.preventDefault(),
                    navigate(lang === "en" ? "/en/Jobs" : "/fr/Emplois"),
                  ]}
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  p={1}
                >
                  {lang === "en" ? "Jobs" : "Emplois"}
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  onClick={(e) => [
                    e.preventDefault(),
                    navigate(lang === "en" ? "/en/Contact" : "/fr/Contact"),
                  ]}
                  href="#"
                  variant="button"
                  color="text"
                  fontWeight="regular"
                  p={1}
                >
                  {lang === "en" ? "Contact" : "Contact"}
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <Button
                  id="basic-button"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  color="text"
                >
                  {lang === "en" ? "Languages" : "Langues"}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem
                    onClick={() => [
                      handleClose(),
                      Cookies.set("lang", "eng", { expires: 365 }),
                      navigate("/en"),
                      RefreshPage(),
                    ]}
                  >
                    EN
                  </MenuItem>
                  <MenuItem
                    onClick={() => [
                      handleClose(),
                      Cookies.set("lang", "fr", { expires: 365 }),
                      navigate("/fr"),
                      RefreshPage(),
                    ]}
                  >
                    FR
                  </MenuItem>
                </Menu>
                <Grid item xs={12} lg={6} textAlign="center">
                  <Menu
                    anchorEl={dropdown}
                    open={Boolean(dropdown)}
                    onClose={closeDropdown}
                  >
                    <MenuItem
                      onClick={() => [
                        closeDropdown(),
                        navigate(lang === "en" ? "/en" : "/fr"),
                      ]}
                    >
                      {lang === "en" ? "Home" : "Acceuil"}
                    </MenuItem>
                    <MenuItem
                      onClick={() => [
                        closeDropdown(),
                        navigate(
                          lang === "en" ? "/en/services" : "/fr/services"
                        ),
                      ]}
                    >
                      {lang === "en" ? "Services" : "Services"}
                    </MenuItem>
                    <MenuItem
                      onClick={() => [
                        closeDropdown(),
                        navigate(
                          lang === "en" ? "/en/references" : "/fr/references"
                        ),
                      ]}
                    >
                      {" "}
                      {lang === "en" ? "References" : "Références"}{" "}
                    </MenuItem>
                    <MenuItem
                      onClick={() => [
                        closeDropdown(),
                        navigate(lang === "en" ? "/en/Jobs" : "/fr/Emplois"),
                      ]}
                    >
                      {" "}
                      {lang === "en" ? "Jobs" : "Emplois"}{" "}
                    </MenuItem>
                    <MenuItem
                      onClick={() => [
                        closeDropdown(),
                        navigate(lang === "en" ? "/en/Contact" : "/fr/Contact"),
                      ]}
                    >
                      {" "}
                      {lang === "en" ? "Contact" : "Contact"}{" "}
                    </MenuItem>
                    {lang === "en" ? (
                      <MenuItem
                        onClick={() => [
                          closeDropdown(),
                          Cookies.set("lang", "fr", { expires: 365 }),
                          // setLang("fr"),
                          navigate("/fr"),
                          RefreshPage(),
                        ]}
                      >
                        {/* 26 */}
                        FR
                      </MenuItem>
                    ) : (
                      <MenuItem
                        onClick={() => [
                          closeDropdown(),
                          Cookies.set("lang", "eng", { expires: 365 }),
                          // setLang("eng"),
                          navigate("/en"),
                          RefreshPage(),
                        ]}
                      >
                        EN
                      </MenuItem>
                    )}
                  </Menu>
                </Grid>
              </MKBox>
            </MKBox>

            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li">
                <MKTypography
                  target="_blank"
                  component={Link}
                  href="https://www.linkedin.com/company/techmedconsulting/"
                  variant="button"
                  p={1}
                >
                  <MKBox
                    component="i"
                    color="text"
                    className="fab fa-linkedin"
                  />
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox>

      <MKBox
        display={window.location.href.length > 34 ? "none" : "flex"}
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: ({
            palette: { gradients },
            functions: { linearGradient, rgba },
          }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.5),
              rgba(gradients.info.state, 0.5)
            )}, url(${cover})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={8}
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            textAlign="center"
            mx="auto"
          >
            <MKTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {lang === "en"
                ? "Your digital partner in the creation of "
                : "Votre partenaire digital dans la création de "}
              <span ref={typedJSRef} />
            </MKTypography>
            <MKTypography
              variant="body1"
              color="white"
              mt={1}
              mb={6}
              px={{ xs: 3, lg: 6 }}
            >
              {lang === "en"
                ? "Techmed is a digital service company (ESN) with a human dimension which relies on 12 years of experience to support their clients in carrying out projects"
                : "Techmed est une entreprise de services du numérique (ESN) à dimension humaine qui s'appuie sur 12 ans d'expérience pour accompagner ses clients dans la réalisation de projets"}
            </MKTypography>

            <MKButton
              color="white"
              hre="#"
              onClick={() =>
                navigate(lang === "en" ? "/en/Contact" : "/fr/Contact")
              }
            >
              {lang === "en" ? "contact us" : "Contactez nous"}
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Header;
