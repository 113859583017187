import { useEffect, useState } from "react";
import Cookies from "js-cookie";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 PRO React components
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 PRO React examples
import HorizontalTeamCard from "../../../examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import bgImage from "../../img/city.jpg";

// Content For teams
import teams from "../Files_json/lang_content/team_content_txt.json";

function Teams() {
  const [Lang, setLang] = useState(Cookies.get("lang"));
  useEffect(() => {
    if (Lang != Cookies.get("lang")) {
      setLang(Cookies.get("lang"));
    }
  });

  return (
    <MKBox
      component="section"
      position="relative"
      py={12}
      sx={{
        backgroundImage: ({
          palette: { gradients },
          functions: { linearGradient, rgba },
        }) =>
          `${linearGradient(
            rgba(gradients.dark.main, 0.8),
            rgba(gradients.dark.state, 0.8)
          )}, url(${bgImage})`,
      }}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKBox
              display="flex"
              alignItems="center"
              justifyContent="center"
              width="3rem"
              height="3rem"
              variant="gradient"
              bgColor="info"
              color="white"
              shadow="md"
              borderRadius="lg"
              mb={2}
            >
              <Icon>supervisor_account</Icon>
            </MKBox>
            <MKTypography variant="h3" color="white">
              {Lang === "eng" ? "The Executive Team" : "L'équipe exécutive"}
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
              {Lang === "eng"
                ? "Here is our teams of experts who have served you and found solutions to your projects"
                : "Voici nos équipes d'experts qui vous ont servi et trouvé des solutions à vos projets"}
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          {teams.map((team) => (
            <Grid item xs={12} lg={6}>
              <MKBox mb={1}>
                <HorizontalTeamCard
                  // image={team2}
                  name={team.Name}
                  position={{
                    color: "info",
                    label: Lang === "eng" ? team.JobEN : team.JobFR,
                  }}
                />
              </MKBox>
            </Grid>
          ))}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Teams;
